import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';

import user from '@iso/redux/user/reducer';
import company from '@iso/redux/company/reducer';
import contact from '@iso/redux/contact/reducer';
import ticket from '@iso/redux/ticket/reducer';
import template from '@iso/redux/template/reducer';
import survey from '@iso/redux/survey/reducer';
import clientSurvey from '@iso/redux/clientSurvey/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,

  modal,
  drawer,

  user,
  company,
  contact,
  ticket,

  template,
  survey,
  
  clientSurvey,
});
