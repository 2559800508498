const surveyActions = {
  // SAGA
  GET_SURVEYS: 'GET_SURVEYS',
  GET_SURVEY: 'GET_SURVEY',

  // REDUCER
  SET_SURVEYS: 'SET_SURVEYS',
  SET_SURVEY: 'SET_SURVEY',

  SET_USERS: 'SET_USERS',

  // SEARCH
  SURVEY_ERROR: 'SURVEY_ERROR',


  // Saga functions
  getSurveys: surveys => ({
    type: surveyActions.GET_SURVEYS,
    payload: surveys,
  }),

  getSurvey: surveys => ({
    type: surveyActions.GET_SURVEY,
    payload: surveys,
  }),
  
  // Reducer functions
  setSurveys: surveys => ({
    type: surveyActions.SET_SURVEYS,
    payload: surveys,
  }),

  setSurvey: survey => ({
    type: surveyActions.SET_SURVEY,
    payload: survey,
  }),

  setUsers: users => ({
    type: surveyActions.SET_USERS,
    payload: users,
  }),
};

export default surveyActions;
