const userActions = {
  // SAGA
  GET_USERS: 'GET_USERS',
  GET_USER: 'GET_USER',
  CREATE_OR_UPDATE_USER_WATCHER: 'CREATE_OR_UPDATE_USER_WATCHER',

  // REDUCER
  SET_USERS: 'SET_USERS',
  SET_USER: 'SET_USER',

  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',

  // SEARCH
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_TYPE_FILTER: 'SET_TYPE_FILTER',

  USER_ERROR: 'USER_ERROR',
  RESET_USER_STATUS: 'RESET_USER_STATUS',

  // Saga functions
  getUsers: users => ({
    type: userActions.GET_USERS,
    payload: users,
  }),

  getUser: users => ({
    type: userActions.GET_USER,
    payload: users,
  }),

  
  createOrUpdateUserWatcher: user => {
    return {
      type: userActions.CREATE_OR_UPDATE_USER_WATCHER,
      payload: user,
    };
  },

  // Reducer functions
  setUsers: users => ({
    type: userActions.SET_USERS,
    payload: users,
  }),

  setUser: user => ({
    type: userActions.SET_USER,
    payload: user,
  }),

  setSearchText: payload => {
    return {
      type: userActions.SET_SEARCH_TEXT,
      payload,
    };
  },

  setTypeFilter: payload => {
    return {
      type: userActions.SET_TYPE_FILTER,
      payload,
    };
  },

  createUser: user => {
    return {
      type: userActions.CREATE_USER,
      payload: user,
    };
  },
  updateUser: user => {
    return {
      type: userActions.UPDATE_USER,
      payload: user,
    };
  },
  resetUserStatus: () => {
    return {
      type: userActions.RESET_USER_STATUS,
    };
  },
};

export default userActions;
