import { nonAccentVietnamese } from '@iso/lib/helpers/utility';

import userActions from './actions';

const initialState = {
  users: [],
  user: {},
  isFetched: false,
  searchText: '',
  typeFilter: [],
  errorMessage: '',
  status: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case userActions.SET_USERS:
      return {
        ...state,
        users: action.payload.map(item => {
          return {
            ...item,
            keyword: `${nonAccentVietnamese(item.displayName)} ${item.phone} ${item.email}`,
          }
        }),
        isFetched: true,
        searchText: '',
      };

    case userActions.SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case userActions.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case userActions.SET_TYPE_FILTER:
      return {
        ...state,
        typeFilter: action.payload,
      };

    case userActions.CREATE_USER:
      return {
        ...state,
        users:[
          {
            ...action.payload,
            keyword: `${nonAccentVietnamese(action.payload.displayName)} ${action.payload.phone} ${action.payload.email}`,
          },
          ...state.users,
        ],
        status: 'CREATED',
        user: {},
      };
    case userActions.UPDATE_USER:
      return {
        ...state,
        users: [
          ...state.users.map(user => {
            return user.id === action.payload.id ? {
              ...user, 
              ...action.payload,
              keyword: `${nonAccentVietnamese(action.payload.displayName)} ${action.payload.phone} ${action.payload.email}`,
            } : user;
          })
        ],
        status: 'UPDATED',
        user: {},
      };
    case userActions.USER_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };
    case userActions.RESET_USER_STATUS:
      return {
        ...state,
        errorMessage: action.payload,
        status: '',
      };
    default:
      return state;
  }
}
