import { all } from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';

import companiesSagas from '@iso/redux/company/saga';
import contactsSagas from '@iso/redux/contact/saga';
import ticketsSagas from '@iso/redux/ticket/saga';
import userSaga from '@iso/redux/user/saga';

import templatesSagas from '@iso/redux/template/saga';
import surveysSagas from '@iso/redux/survey/saga';
import clientSurveySagas from '@iso/redux/clientSurvey/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    companiesSagas(),
    contactsSagas(),
    ticketsSagas(),
    userSaga(),
    templatesSagas(),
    surveysSagas(),
    clientSurveySagas(),
  ]);
}
