import { nonAccentVietnamese } from '@iso/lib/helpers/utility';

import surveyActions from './actions';

const initialState = {
  surveys: [],
  users: [],
  total: 0,
  survey: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case surveyActions.SET_SURVEYS:
      const { surveys, total } = action.payload;

      return {
        ...state,
        // surveys: surveys.map(item => {
        //   return {
        //     ...item,
        //     keyword: `${nonAccentVietnamese(item.ticketSubject)}`,
        //   };
        // }),
        surveys,
        total,
        isFetched: true,
      };
    case surveyActions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case surveyActions.SURVEY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };
    default:
      return state;
  }
}
